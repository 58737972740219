import React from 'react'



const Logo = prop => {

    return (

		<svg style={ {...prop} } version='1.1' id='logo' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='200px' height='200px' viewBox='0 0 200 200' enableBackground='new 0 0 200 200' xmlSpace='preserve'>
        <symbol id='Kai' viewBox='-244.868 -211.546 491.152 446.147'>
          <g>
            <path d='M-236.331,10.641c0,120.406,107.6,218.015,240.331,218.015c132.73,0,240.33-97.608,240.33-218.015
  			c0-120.404-107.6-218.012-240.33-218.012C-128.731-207.371-236.331-109.764-236.331,10.641z' />
            <path fill='#272728' d='M-244.864,10.879c0,0,0.021,1.407,0.063,4.133c0.076,2.762-0.03,6.631,0.475,12.357
  			c0.311,5.674,1.092,12.393,2.357,20.289c0.363,1.966,0.739,4.006,1.129,6.118c0.207,1.054,0.378,2.134,0.627,3.216
  			c0.264,1.079,0.53,2.175,0.802,3.289c0.545,2.228,1.107,4.526,1.687,6.893c0.658,2.344,1.409,4.736,2.156,7.201
  			c0.38,1.231,0.764,2.478,1.154,3.742c0.416,1.254,0.903,2.502,1.362,3.778c0.947,2.541,1.861,5.168,2.908,7.813
  			c1.126,2.611,2.237,5.304,3.485,8.005c0.587,1.368,1.346,2.672,2.082,4c0.74,1.327,1.488,2.668,2.243,4.021
  			c5.817,10.924,12.856,22.338,21.613,33.588c8.728,11.26,19.085,22.392,31.095,32.842c12.004,10.45,25.659,20.217,40.809,28.772
  			c1.903,1.052,3.767,2.198,5.73,3.185c1.957,0.994,3.925,1.994,5.902,2.999c0.988,0.501,1.979,1.005,2.973,1.509
  			c0.996,0.499,2.027,0.93,3.043,1.4c2.039,0.921,4.089,1.846,6.148,2.775c2.04,0.971,4.174,1.75,6.286,2.606
  			c2.117,0.841,4.244,1.687,6.38,2.535c2.17,0.756,4.351,1.515,6.54,2.276c1.095,0.379,2.193,0.758,3.293,1.138l1.651,0.569
  			l1.678,0.5c17.851,5.547,36.869,9.381,56.595,11.142c1.229,0.15,2.467,0.214,3.708,0.283c1.239,0.072,2.481,0.145,3.725,0.217
  			c2.488,0.126,4.979,0.333,7.485,0.39c2.506,0.03,5.02,0.061,7.542,0.092c1.26,0.016,2.522,0.029,3.787,0.045
  			c0.034,0.001,0.484,0.007,0.607-0.002l0.464-0.016l0.929-0.031l1.858-0.063c2.48-0.085,4.969-0.169,7.464-0.254
  			c1.232-0.02,2.523-0.125,3.815-0.231c1.289-0.102,2.58-0.204,3.873-0.307c1.292-0.103,2.586-0.206,3.882-0.309
  			c1.294-0.109,2.6-0.186,3.871-0.371c2.56-0.323,5.125-0.647,7.698-0.973c1.285-0.174,2.578-0.298,3.859-0.516
  			c1.281-0.223,2.564-0.443,3.849-0.666c2.567-0.447,5.142-0.895,7.722-1.344c2.559-0.564,5.123-1.131,7.693-1.697l3.859-0.854
  			l3.828-1.021c2.553-0.688,5.111-1.376,7.677-2.066c2.531-0.803,5.069-1.607,7.612-2.414l3.815-1.217l3.769-1.385
  			c2.511-0.93,5.028-1.862,7.55-2.797c2.478-1.047,4.959-2.096,7.446-3.147c1.24-0.534,2.496-1.033,3.725-1.599l3.665-1.75
  			c2.437-1.188,4.914-2.299,7.326-3.555c2.397-1.283,4.799-2.569,7.205-3.857c2.433-1.238,4.732-2.729,7.098-4.105
  			c2.341-1.42,4.739-2.751,7.002-4.313c2.291-1.514,4.586-3.03,6.885-4.549c2.257-1.579,4.465-3.236,6.703-4.854
  			c8.844-6.615,17.412-13.74,25.295-21.652c7.918-7.86,15.454-16.212,22.144-25.271c13.583-17.945,24.393-38.192,31.735-59.7
  			c3.575-10.786,6.359-21.845,8.204-33.076c1.706-11.253,2.527-22.625,2.507-33.993c-0.226-11.294-1.299-22.716-3.244-33.867
  			c-2.083-11.147-5.094-22.082-8.877-32.707c-3.789-10.638-8.74-20.762-13.898-30.705c-1.352-2.457-2.702-4.912-4.052-7.365
  			c-1.471-2.384-2.94-4.765-4.408-7.145l-1.103-1.785c-0.378-0.587-0.787-1.153-1.18-1.731l-2.377-3.451l-2.376-3.449
  			c-0.797-1.146-1.68-2.227-2.516-3.343c-6.618-8.985-14.081-17.271-21.924-25.074c-7.808-7.856-16.299-14.931-25.064-21.504
  			c-2.219-1.607-4.407-3.255-6.645-4.824c-2.279-1.509-4.555-3.016-6.826-4.521c-2.243-1.553-4.623-2.877-6.944-4.288
  			c-2.346-1.369-4.625-2.852-7.039-4.081c-2.386-1.281-4.769-2.561-7.146-3.837c-2.392-1.25-4.851-2.354-7.268-3.536l-3.637-1.741
  			c-1.219-0.562-2.465-1.06-3.694-1.591c-2.468-1.047-4.932-2.091-7.389-3.134c-2.503-0.93-5.001-1.858-7.493-2.784l-3.74-1.379
  			l-3.786-1.212c-2.524-0.804-5.043-1.605-7.557-2.405c-2.546-0.688-5.086-1.376-7.62-2.061l-3.801-1.02l-3.831-0.851
  			c-2.553-0.566-5.099-1.131-7.639-1.694c-2.562-0.448-5.119-0.896-7.668-1.343c-1.276-0.222-2.55-0.443-3.822-0.665
  			c-1.272-0.219-2.557-0.342-3.833-0.518c-2.556-0.325-5.104-0.649-7.646-0.974c-2.543-0.327-5.097-0.447-7.635-0.68
  			c-1.271-0.103-2.539-0.205-3.806-0.308c-1.267-0.107-2.532-0.214-3.799-0.236c-2.533-0.088-5.059-0.177-7.576-0.265l-1.888-0.065
  			l-0.943-0.032l-0.471-0.017c-0.138-0.009-0.492,0.002-0.562,0.001c-1.237,0.014-2.472,0.026-3.705,0.04
  			c-2.466,0.026-4.925,0.053-7.375,0.08c-2.463,0.052-4.992,0.256-7.473,0.378c-1.246,0.071-2.489,0.143-3.731,0.214
  			c-1.243,0.068-2.485,0.13-3.703,0.278c-19.601,1.729-38.5,5.518-56.243,11.009l-1.668,0.494l-1.642,0.564
  			c-1.093,0.377-2.184,0.752-3.273,1.127c-2.176,0.755-4.343,1.507-6.5,2.255c-2.124,0.841-4.238,1.679-6.342,2.512
  			c-2.099,0.849-4.22,1.62-6.249,2.583c-2.047,0.922-4.084,1.839-6.111,2.752c-1.009,0.465-2.035,0.893-3.024,1.388
  			c-0.988,0.499-1.973,0.998-2.955,1.495c-1.967,0.997-3.922,1.988-5.869,2.975c-1.951,0.976-3.804,2.114-5.696,3.157
  			c-15.061,8.484-28.637,18.173-40.573,28.542c-11.943,10.368-22.242,21.415-30.924,32.589
  			c-8.689,11.175-15.756,22.472-21.458,33.357c-0.702,1.368-1.396,2.722-2.083,4.062c-0.695,1.336-1.375,2.663-1.953,4.021
  			c-1.207,2.692-2.389,5.326-3.543,7.9c-1.082,2.605-2.031,5.195-3.015,7.701c-0.477,1.259-0.981,2.49-1.417,3.728
  			c-0.406,1.248-0.809,2.479-1.206,3.695c-6.457,19.442-8.772,35.533-9.749,46.454c-0.627,5.468-0.641,9.688-0.783,12.526
  			c-0.109,2.84-0.166,4.306-0.166,4.306L-244.864,10.879z M-227.795,10.641c0,0,0.021-1.347,0.063-3.957
  			c0.071-2.609-0.024-6.494,0.44-11.53c0.652-10.068,2.419-24.919,7.954-43.05c0.339-1.135,0.683-2.284,1.03-3.449
  			c0.375-1.155,0.818-2.306,1.23-3.481c0.857-2.343,1.676-4.769,2.62-7.211c1.015-2.416,2.053-4.889,3.114-7.415
  			c0.504-1.277,1.106-2.525,1.725-3.783c0.609-1.262,1.227-2.537,1.849-3.826c5.063-10.264,11.405-20.98,19.306-31.666
  			c7.892-10.685,17.34-21.331,28.402-31.415c11.057-10.084,23.725-19.603,37.887-28.04c1.781-1.036,3.52-2.171,5.361-3.145
  			c1.834-0.986,3.68-1.978,5.533-2.974c0.927-0.499,1.855-0.998,2.787-1.5c0.936-0.496,1.905-0.926,2.859-1.395
  			c1.918-0.92,3.845-1.844,5.781-2.773c1.918-0.972,3.931-1.754,5.921-2.615c1.997-0.849,4.002-1.7,6.017-2.557
  			c2.051-0.767,4.111-1.536,6.18-2.31c1.036-0.386,2.074-0.771,3.114-1.158l1.562-0.581l1.59-0.513
  			c16.904-5.682,35.014-9.778,53.917-11.909c1.181-0.174,2.357-0.259,3.536-0.353c1.179-0.096,2.361-0.192,3.544-0.288
  			c2.378-0.175,4.718-0.432,7.153-0.543c2.449-0.086,4.906-0.172,7.372-0.259c1.232-0.043,2.467-0.086,3.703-0.13
  			c0.239-0.004,0.194-0.015,0.366-0.01l0.457,0.006l0.914,0.01l1.831,0.02c2.443,0.026,4.896,0.052,7.354,0.078
  			c1.231-0.01,2.46,0.064,3.69,0.14c1.231,0.07,2.465,0.141,3.699,0.21c2.468,0.167,4.954,0.221,7.429,0.479
  			c2.476,0.255,4.959,0.51,7.448,0.767c1.244,0.14,2.496,0.228,3.737,0.411l3.729,0.558c2.49,0.374,4.987,0.749,7.489,1.124
  			c2.484,0.488,4.974,0.979,7.47,1.47l3.751,0.736l3.723,0.903c2.485,0.607,4.976,1.217,7.473,1.827
  			c2.468,0.72,4.94,1.442,7.419,2.166l3.724,1.092l3.678,1.257c2.455,0.845,4.914,1.691,7.38,2.54
  			c2.424,0.96,4.853,1.921,7.286,2.885c1.214,0.49,2.445,0.945,3.649,1.467l3.593,1.616c2.389,1.098,4.822,2.118,7.19,3.284
  			c2.356,1.192,4.717,2.388,7.081,3.584c2.395,1.146,4.656,2.544,6.987,3.829c2.306,1.327,4.672,2.566,6.904,4.035
  			c2.263,1.421,4.529,2.844,6.799,4.27c2.23,1.485,4.414,3.049,6.631,4.573c8.755,6.241,17.271,12.989,25.136,20.531
  			c7.9,7.489,15.455,15.476,22.197,24.182c0.851,1.081,1.75,2.127,2.563,3.239l2.427,3.347l2.431,3.353
  			c0.402,0.562,0.82,1.112,1.208,1.684l1.131,1.736c1.509,2.317,3.019,4.637,4.53,6.958c1.394,2.395,2.789,4.791,4.185,7.189
  			c1.461,2.366,2.616,4.897,3.89,7.368c0.629,1.24,1.251,2.483,1.854,3.737c0.621,1.244,1.107,2.552,1.667,3.825
  			c1.064,2.572,2.212,5.111,3.199,7.715l2.833,7.862c3.504,10.581,6.231,21.426,8.039,32.439c1.669,11.054,2.468,22.11,2.451,33.335
  			c-0.229,11.15-1.281,22.258-3.189,33.209c-2.046,10.93-5.002,21.65-8.713,32.07c-7.614,20.777-18.545,40.19-32.093,57.287
  			c-6.671,8.632-14.152,16.555-21.978,23.986c-7.789,7.484-16.229,14.184-24.906,20.382c-2.196,1.514-4.36,3.066-6.571,4.543
  			c-2.251,1.416-4.498,2.829-6.741,4.24c-2.212,1.461-4.56,2.691-6.846,4.01c-2.312,1.277-4.553,2.667-6.928,3.806
  			c-2.346,1.19-4.687,2.378-7.023,3.563c-2.35,1.158-4.764,2.174-7.133,3.266l-3.563,1.607c-1.194,0.518-2.416,0.971-3.62,1.458
  			c-2.414,0.959-4.824,1.916-7.229,2.871c-2.446,0.845-4.887,1.687-7.322,2.526l-3.65,1.252l-3.694,1.087
  			c-2.46,0.722-4.914,1.44-7.362,2.157c-2.479,0.609-4.951,1.216-7.417,1.821l-3.695,0.9l-3.723,0.734
  			c-2.479,0.49-4.949,0.979-7.415,1.466c-2.484,0.376-4.964,0.75-7.436,1.123l-3.701,0.559c-1.232,0.183-2.477,0.271-3.712,0.411
  			c-2.472,0.257-4.938,0.513-7.396,0.769c-1.232,0.154-2.447,0.193-3.665,0.269c-1.217,0.069-2.432,0.14-3.645,0.21
  			s-2.424,0.141-3.633,0.211c-1.205,0.076-2.407,0.152-3.673,0.145c-2.497,0.029-4.986,0.059-7.468,0.088l-1.859,0.022l-0.929,0.011
  			l-0.465,0.006c-0.186,0.006-0.045-0.01-0.319-0.012c-1.209-0.042-2.417-0.084-3.622-0.125c-2.41-0.082-4.812-0.164-7.206-0.246
  			c-2.393-0.107-4.769-0.361-7.141-0.533c-1.186-0.095-2.369-0.189-3.55-0.284c-1.182-0.092-2.361-0.177-3.531-0.348
  			c-18.777-2.101-36.77-6.151-53.564-11.778l-1.58-0.507l-1.552-0.576c-1.034-0.384-2.065-0.766-3.094-1.147
  			c-2.056-0.766-4.103-1.529-6.141-2.289c-2.001-0.848-3.995-1.692-5.978-2.533c-1.978-0.854-3.978-1.629-5.883-2.592
  			c-1.924-0.921-3.839-1.837-5.744-2.749c-0.948-0.464-1.913-0.89-2.841-1.382c-0.926-0.498-1.85-0.993-2.771-1.488
  			c-1.842-0.986-3.675-1.969-5.499-2.947c-1.829-0.965-3.557-2.09-5.326-3.118c-14.074-8.366-26.664-17.807-37.652-27.81
  			c-10.995-10.002-20.385-20.564-28.23-31.164c-7.833-10.61-14.2-21.211-19.148-31.437c-2.326-5.194-4.287-10.259-6.456-14.981
  			c-0.979-2.403-1.833-4.792-2.726-7.099c-0.432-1.159-0.893-2.292-1.285-3.432c-0.365-1.149-0.727-2.283-1.083-3.402
  			c-0.702-2.241-1.452-4.404-2.091-6.532c-0.569-2.148-1.122-4.234-1.658-6.256c-0.27-1.012-0.535-2.007-0.795-2.987
  			c-0.248-0.983-0.419-1.966-0.627-2.924c-0.404-1.919-0.795-3.773-1.172-5.56c-1.336-7.176-2.215-13.384-2.601-18.24
  			c-0.589-4.778-0.61-9.013-0.749-11.698c-0.109-2.724-0.167-4.13-0.167-4.13L-227.795,10.641z' />
          </g>
          <g>
            <path fill='#492B23' d='M127.486-129.764c0-38.66-31.34-70-70-70h-100.58c-38.66,0-70,31.34-70,70v54.203
  			c0,38.659,31.34,69.999,70,69.999h100.58c38.66,0,70-31.34,70-69.999V-129.764z' />
            <path fill='#35210A' d='M128.617-129.792c0,0-0.007-0.732-0.021-2.15c-0.005-0.708-0.004-1.584-0.087-2.663
  			c-0.075-1.067-0.106-2.322-0.289-3.703c-0.599-5.522-2.015-13.764-6.55-23.255c-2.279-4.714-5.273-9.751-9.321-14.637
  			c-4.02-4.9-9.045-9.685-15.126-13.838c-6.077-4.141-13.212-7.655-21.209-9.913c-3.999-1.112-8.202-1.916-12.55-2.345
  			c-2.162-0.243-4.409-0.305-6.596-0.374c-2.149-0.038-4.321-0.075-6.518-0.113c-17.563-0.273-36.592-0.569-56.717-0.883
  			c-10.063-0.144-20.4-0.292-30.966-0.443c-1.321-0.013-2.645-0.026-3.973-0.039l-2.105-0.013l-2,0.031
  			c-1.281-0.01-2.798,0.113-4.255,0.208c-1.485,0.073-2.857,0.305-4.297,0.454c-5.69,0.756-11.339,2.253-16.8,4.362
  			c-5.469,2.101-10.706,4.932-15.572,8.359c-2.459,1.681-4.762,3.593-7.025,5.559c-2.2,2.036-4.364,4.135-6.33,6.43
  			c-8.034,9-13.799,20.376-15.846,32.484c-0.551,3.015-0.838,6.068-1.012,9.115c-0.014,1.529-0.112,3.051-0.049,4.575
  			c0.047,1.526,0.068,3.062,0.16,4.483c0.164,2.901,0.288,5.808,0.391,8.717c0.123,2.91,0.206,5.823,0.263,8.738
  			c0.128,11.664,0.257,23.374,0.386,35.083c0.072,1.596,0.064,2.923,0.199,4.482c0.13,1.528,0.238,3.066,0.474,4.549
  			c0.362,2.999,1.026,5.949,1.753,8.872c1.575,5.817,3.85,11.434,6.779,16.663c5.902,10.433,14.333,19.334,24.303,25.641
  			c2.468,1.617,5.083,2.978,7.711,4.28c2.67,1.214,5.367,2.364,8.151,3.245c2.76,0.952,5.595,1.631,8.429,2.242
  			c2.851,0.526,5.713,0.965,8.588,1.152l2.153,0.148l2.154,0.044c1.445,0.041,2.843,0.021,4.247,0.011
  			c2.806-0.021,5.604-0.04,8.391-0.061c5.574-0.029,11.109-0.059,16.598-0.087C6.487-4.484,27.711-4.586,47.837-4.683
  			c2.516-0.013,5.014-0.024,7.494-0.036l1.857-0.009l1.871-0.049l1.843-0.056c0.616-0.028,1.241-0.094,1.859-0.139
  			c4.951-0.388,9.744-1.283,14.34-2.63c9.189-2.688,17.462-7.178,24.352-12.734c6.908-5.554,12.429-12.164,16.555-19.081
  			c2.082-3.452,3.761-7.02,5.139-10.575c1.353-3.564,2.496-7.104,3.224-10.624c0.226-0.87,0.347-1.755,0.516-2.622
  			c0.16-0.869,0.326-1.73,0.421-2.595c0.113-0.862,0.227-1.717,0.339-2.563c0.07-0.852,0.141-1.696,0.211-2.533
  			c0.24-3.359,0.212-6.585,0.237-9.664c0.045-6.175,0.085-11.847,0.122-16.97c0.12-10.246,0.215-18.296,0.278-23.785
  			c0.081-5.488,0.124-8.416,0.124-8.416L128.617-129.792z M126.354-129.764c0,0,0.043,2.928,0.124,8.416
  			c0.063,5.489,0.158,13.539,0.278,23.785c0.037,5.123,0.077,10.795,0.122,16.97c0.021,3.093,0.093,6.301-0.096,9.595
  			c-0.141,3.308-0.664,6.707-1.364,10.177c-1.461,6.929-4.15,14.089-8.257,20.899c-4.11,6.798-9.628,13.242-16.465,18.639
  			c-6.824,5.396-14.983,9.721-24.005,12.265c-4.501,1.274-9.229,2.106-14.027,2.437c-0.601,0.038-1.195,0.097-1.804,0.119
  			l-1.844,0.037l-1.829,0.03l-1.857-0.009c-2.48-0.012-4.979-0.023-7.494-0.036C27.711-6.537,6.487-6.639-15.468-6.744
  			c-5.489-0.028-11.023-0.058-16.598-0.087c-2.788-0.021-5.585-0.04-8.391-0.061c-1.403-0.01-2.817-0.01-4.186-0.069l-2.072-0.071
  			l-2.068-0.173c-2.761-0.22-5.502-0.682-8.23-1.224c-2.709-0.628-5.416-1.314-8.045-2.265c-2.653-0.881-5.216-2.021-7.752-3.215
  			c-2.493-1.283-4.973-2.615-7.306-4.193c-9.445-6.136-17.32-14.765-22.677-24.76c-2.667-5.004-4.7-10.346-6.07-15.847
  			c-0.623-2.765-1.193-5.547-1.473-8.37c-0.196-1.414-0.259-2.801-0.354-4.19c-0.099-1.358-0.065-2.964-0.103-4.293
  			c0.129-11.709,0.258-23.419,0.386-35.083c0.057-2.915,0.14-5.828,0.263-8.738c0.103-2.909,0.227-5.815,0.391-8.717
  			c0.062-1.479,0.175-2.843,0.295-4.214c0.099-1.373,0.354-2.73,0.518-4.088c0.48-2.698,1.015-5.371,1.801-7.966
  			c1.459-5.214,3.513-10.188,5.998-14.88c1.208-2.365,2.615-4.613,4.044-6.833c1.499-2.174,3.041-4.317,4.776-6.293
  			c1.683-2.022,3.551-3.868,5.444-5.671c1.955-1.733,3.938-3.433,6.068-4.921c2.089-1.547,4.312-2.882,6.547-4.17
  			c2.276-1.211,4.578-2.364,6.955-3.315c4.738-1.936,9.659-3.351,14.647-4.109c1.239-0.147,2.521-0.393,3.716-0.467
  			c1.218-0.1,2.367-0.241,3.757-0.26l1.998-0.07l1.883-0.022c1.328-0.013,2.652-0.026,3.973-0.039
  			c10.566-0.151,20.903-0.3,30.966-0.443c20.125-0.313,39.154-0.609,56.717-0.883c2.196-0.038,4.368-0.075,6.518-0.113
  			c2.11-0.004,4.118-0.021,6.142,0.135c4.027,0.257,7.941,0.864,11.688,1.768c7.487,1.842,14.279,4.865,20.099,8.589
  			c5.829,3.722,10.715,8.087,14.665,12.611c3.979,4.51,6.975,9.211,9.289,13.641c4.608,8.919,6.223,16.798,6.963,22.105
  			c0.221,1.336,0.281,2.509,0.387,3.525c0.112,1.005,0.14,1.891,0.166,2.601c0.058,1.416,0.088,2.147,0.088,2.147L126.354-129.764z' />
          </g>
          <g id='Layer_3_3_'>
            <path stroke='#535659' strokeMiterlimit={10} d='M-38.549-102.656c-6.395-0.324-12.395,15.451-13.4,35.234
  			c-1.006,19.783,3.363,36.085,9.757,36.411c6.395,0.324,12.395-15.451,13.4-35.233C-27.785-86.029-32.154-102.332-38.549-102.656z' />
            <path fill='#FFFFFF' d='M-39.261-74.744c-3.895-0.197-7.549,9.412-8.162,21.46c-0.613,12.051,2.048,21.98,5.943,22.178
  			c3.896,0.199,7.55-9.41,8.163-21.461C-32.705-64.615-35.365-74.545-39.261-74.744z' />
            <path stroke='#535659' strokeMiterlimit={10} d='M44.867-102.549c-6.375,0.596-10.055,17.066-8.217,36.791
  			c1.838,19.724,8.496,35.231,14.871,34.638s10.055-17.065,8.217-36.788C57.9-87.633,51.242-103.143,44.867-102.549z' />
            <path fill='#FFFFFF' d='M47.234-74.67c-3.881,0.381-6.076,10.424-4.902,22.433c1.176,12.01,5.275,21.437,9.156,21.057
  			s6.076-10.423,4.902-22.432C55.215-65.619,51.117-75.047,47.234-74.67z' />
          </g>
          <path fill='#935448' d='M-44.529-195.719c0,0-30,0-49.667,25c-14.282,18.154-18,25.166-16.333,56.5s1.307,45.834,1.307,45.834
  		s3.727,28.856,23.693,43.998C-85.529-24.387-121.529-124.719-44.529-195.719z' />
        </symbol>
        <use xlinkHref='#Kai' width='491.152' height='446.147' id='XMLID_1_' x='-244.868' y='-211.546' transform='matrix(0.3448 0 0 -0.3698 99.0137 103.7646)' overflow='visible' />
      </svg>
    )
}

export default Logo