import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import StaticLogo from './../components/StaticLogo'
import { useTrail, animated } from 'react-spring'
import { Trail } from 'react-spring/renderprops'
import { FaPinterest, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

const IndexPage = () => {
  const socialMedia = [
    {
      name: 'Pinterest',
      component: FaPinterest,
      href: 'https://www.pinterest.com/optymystyc',
      className: 'pinterest'
    },
    {
      name: 'LinkedIn',
      component: FaLinkedinIn,
      href: 'https://www.linkedin.com/in/berato-wilson-a6a8a9b/',
      className: 'linkedin'
    },
    {
      name: 'Twitter',
      component: FaTwitter,
      href: 'https://twitter.com/optymynd',
      className: 'twitter'
    }
  ]

  // Step 1: Create each piece of content as a function that returns an animated component
  const Logo = ({ style }) => <animated.div style={{ display: 'flex', justifyContent: 'center', ...style }}><StaticLogo {...style} display='flex' position='static' opacity={1} margin={0} /></animated.div>
  const Heading = ({ style }) => <animated.h1 style={style} className='text-center header-style my-name'>Yer-Berato Wilson</animated.h1>
  const JobTitle = ({ style }) => <animated.h2 style={style} className='text-center header-style job-title'>Senior Front End Engineer</animated.h2>
  const PersonalStatement = ({ style }) => <animated.p style={style} className='lead personal-statement text-center'>I'm a Software Engineer with a love for solving complex problems on the front-end with Javascript. I'm also a Machine Learning hobbyist experimenting with Tensorflow, Brain.js, CNNs and GANs. I'm currently working on a Youtube series for developers! Please do check out my social media links below! (Don't mind all of that Street Fighter stuff!)</animated.p>
  const CurrentRole = ({ style }) => <animated.p style={style} className='lead personal-statement text-center'>I'm currently a Senior Engineer at Target!</animated.p>

  // Step 2: Put all of those pieces of content in an array. This groups them together for easier use.
  const content = [
    Logo,
    Heading,
    JobTitle,
    PersonalStatement,
    CurrentRole
  ]

  // Step 3: Define the type of animation you'd like to use. Here we use 'useTrails' and pass it the length of our 'content' array.
  const trails = useTrail(content.length, {
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    from: {
      opacity: 0,
      transform: 'translate3d(0,-40px,0)'
    }
  })

  return (
    <Layout logoActive={false}>
      <Seo
        title='Home'
        description="I'm a Front End Developer with a love for solving complex problems on the front-end with Javascript. I'm also a Machine Learning hobbyist experimenting with Tensorflow, CNNs and GANs. I'm currently working on a Youtube series for developers! Please do check out my social media links below! (Don't mind all of that Street Fighter stuff!). I'm currently a Senior Engineer at Target!"
      />
      <animated.div className='container-fluid homepage flex-center' style={{}}>
        <div className='row home-content d-flex justify-content-center'>
          <div className='col-xl-6 col-lg-6 col-md-8 col-xs-12 wrapper'>
            {/* Step 4: We use the map function to create each piece of content separately. We use the 'index' (in this case, the variable 'i') to */}
            {trails.map((style, i) => { const Item = content[i]; return <Item key={i} style={style} /> })}
            <nav>
              <ul className='nav social-nav d-flex justify-content-center'>
                <Trail items={socialMedia} keys={item => item.name} from={{ transform: 'translate3d(0, 40px, 0)', opacity: 0 }} to={{ transform: 'translate3d(0,0px,0)', opacity: 1 }}>
                  {item => props =>
                    <li style={props} role='presentation' className='col-md-3 col-xs-12 text-center'>
                      <a href={`${item.href}`} className={`${item.className}`}>
                        <item.component />
                        <span style={{ marginLeft: '5px' }}>
                          {item.name}
                        </span>
                      </a>
                    </li>}
                </Trail>
              </ul>
            </nav>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default IndexPage
